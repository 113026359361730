import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import {
  getFullFormatList,
  OSM_FORMAT_LIST_FULL,
  OSM_FORMAT_LIST_FOR_BIG_AREA,
  DEFAULT_VECTOR_FORMAT,
  COMMON_VECTOR_FORMATS,
} from '@/utils/formatUtils';
import { LAST_UPDATED_TODAY, BIG_AREA_CODES } from '@/constants/product/productConstants';
import { CACHED_COUNTRIES } from '@/config';

const ALLOWED_DATE_MIN = '2009-05';
const NOW = dayjs().format('DD.MM.YY');

const getProductionTimeForCachedProducts = ({ lang, regionCode }) => {
  if (CACHED_COUNTRIES.includes(regionCode.slice(0, 2))) return 10;
  if (regionCode === 'custom') return lang === 'ru' ? 10 : 30;
  return 40;
};

const getProductSettings = (i18n) => {
  return Object.freeze({
    base: {
      id: 'base',
      serverId: 'osm', // server id

      to: { name: 'base', params: { productId: 'base' } },
      name: i18n.t('product.base.title'),
      icon: 'mdi-earth',
      isWorldwide: true,

      description: i18n.t('product.base.description'),
      lastUpdated: LAST_UPDATED_TODAY,
      productionTime: (regionCode) => getProductionTimeForCachedProducts({ lang: i18n.locale, regionCode }), // in minutes
      sources: [
        {
          text: 'OpenStreetMap',
          link: 'https://www.openstreetmap.org/',
        },
      ],
      tags: i18n.t('product.base.tags'),
      samplesUrl: `/${i18n.locale}/about/#formats-base`,
      dataStructureUrl: {
        ru: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTAv39RP6hZWTaC8aU5vhXN64com_hN1ydLThPR0QvfDI6w0CHouYRnJrq7JSrn6xnKDdhTax23w4qd/pubhtml#',
        en: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRIUla6YhWLBPCfKZ6ldWZ_xAO7lc-DtHcS6_MI4knV1RgWgkfm0MOKaQJXHQ1Cnxg8UDl0hXpt4Eqk/pubhtml',
        es: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTmOly1OcD771qSdBp1HCwOZ5NdmTSzCcyEeGs3tNgvMe-EUEvRWF60i_qKKhS7z7EznEPVRoyDMx5F/pubhtml',
        fr: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTUmy8mkLji6CYfend4xT6O8zJ62wIQVGDPIwSfXJ0lml97JxSv_W6byAYkV4PTihJcs_8YCt4ojTJy/pubhtml',
      },
      demoMapUrl: {
        en: 'https://demo.nextgis.com/resource/7003/display?panel=info',
        ru: 'https://demo.nextgis.ru/resource/6630/display?panel=info',
      },
      demoMapImage: `${process.env.VUE_APP_PUBLIC_PATH}img/product/base_demo_map_bg.png`,
      productOptions: (regionCode) => ({
        formFields: {
          format: {
            name: 'format',
            label: `${i18n.t('product.format')}:`,
            widget: 'select',
            options: BIG_AREA_CODES.includes(regionCode)
              ? getFullFormatList(OSM_FORMAT_LIST_FOR_BIG_AREA)
              : getFullFormatList(OSM_FORMAT_LIST_FULL),
            serverErrors: '',
            errors: [],
          },
          actuality: {
            name: 'actuality',
            widget: 'toggle',
            options: [
              {
                value: 'actual',
                text: `${i18n.t('product.actual')} <span class="d-none d-sm-inline">&nbsp;${i18n.t(
                  'product.data',
                )}</span> <small class="text-muted ml-1 d-none d-sm-inline">${NOW}</small>`,
              },
              {
                value: 'historical',
                text: `${i18n.t('product.historical')} <span class="d-none d-sm-inline">&nbsp;${i18n.t(
                  'product.data',
                )}</span>`,
              },
            ],
            serverErrors: '',
            errors: [],
            attrs: {
              mandatory: true,
              color: 'primary',
              class: 'actuality-switcher',
            },
            /* help: {
            text: i18n.t('product.actualityHint'),
            attrs: {
              right: true,
              maxWidth: 260,
            },
          }, */
          },
          actualityDate: {
            name: 'actualityDate',
            widget: 'datepicker',
            label: `${i18n.t('product.date')}:`,
            placeholder: i18n.t('product.datePlaceholder'),
            serverErrors: '',
            errors: [],
            formatFunc: (val) => val && dayjs(val).locale(i18n.locale).format('MMMM YYYY'),
            attrs: {
              datepicker: {
                min: ALLOWED_DATE_MIN,
                max: dayjs().subtract(1, 'months').format('YYYY-MM'),
                locale: i18n.locale,
                noTitle: true,
                type: 'month',
              },
              text: {
                class: 'mb-4',
                autofocus: true,
              },
            },
          },
        },
      }),
      productModel: {
        formModel: {
          format: DEFAULT_VECTOR_FORMAT,
          actuality: 'actual',
          actualityDate: undefined,
        },
      },
      images: [
        { name: 'baseAtd', format: 'png' },
        { name: 'baseHist', format: 'gif' },
        { name: 'baseRoads', format: 'png' },
        { name: 'baseQgis', format: 'png' },
        { name: 'baseMapinfo', format: 'png' },
        { name: 'baseArcgis', format: 'png' },
      ],
    },
    dem: {
      id: 'dem',
      serverId: 'dem',
      to: { name: 'dem', params: { productId: 'dem' } },
      name: i18n.t('product.dem.title'),
      icon: 'mdi-image-filter-hdr',
      isWorldwide: true,

      description: i18n.t('product.dem.description'),
      lastUpdated: '01.04.2023',
      productionTime: 10, // in minutes
      sources: [
        {
          text: 'Copernicus DEM',
          link: 'https://spacedata.copernicus.eu/collections/copernicus-digital-elevation-model',
        },
      ],
      tags: i18n.t('product.dem.tags'),
      samplesUrl: `/${i18n.locale}/about/#formats-dem`,
      dataStructureUrl: {
        ru: 'https://docs.google.com/spreadsheets/d/1BcuWXMnwpwv0HIao3wAumgpqGsgfAzLc5yaOOCTeYBc/pubhtml',
        en: 'https://docs.google.com/spreadsheets/d/1PRSpeLWrFttCS7tz8rk--R7aoV_B8LuGbDuUbhLyWJg/pubhtml',
        es: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vS3Oq01OMLnSVywAzRZ5sXAmc-sKW9GwY8WPU9WfwZlxNMr4qgWrXGR-dFjZBNRj18uCk9vOtI3lYkS/pubhtml',
        fr: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRd0GjSzYWT3WwGaNpAKKt4_HaCObcmYtGIyVkYiMolb31MsDMaoFGHNbr-CZucbUn-11eJ3yvRNDTg/pubhtml',
      },
      demoMapUrl: {
        en: 'https://demo.nextgis.com/resource/4119/display?panel=info',
        ru: 'https://demo.nextgis.ru/resource/4108/display?panel=info',
      },
      demoMapImage: `${process.env.VUE_APP_PUBLIC_PATH}img/product/dem_demo_map_bg.png`,
      productOptions: {
        formFields: {
          format_vector: {
            name: 'format_vector',
            label: `${i18n.t('product.format_vector')}:`,
            widget: 'select',
            options: getFullFormatList(COMMON_VECTOR_FORMATS),
            serverErrors: '',
            errors: [],
          },
          format_raster: {
            name: 'format_raster',
            label: `${i18n.t('product.format_raster')}:`,
            widget: 'select',
            options: getFullFormatList(['geotiff']),
            serverErrors: '',
            errors: [],
          },
          contourlines_step: {
            name: 'contourlines_step',
            label: `${i18n.t('product.contourlines_step')}:`,
            widget: 'select',
            options: [], // get by API for certain region
            serverErrors: '',
            errors: [],
            hintOutside: {
              text: `${i18n.t('product.dem.contourlinesStepHint', {
                locale: i18n.locale,
              })}`,
            },
          },
        },
      },
      productModel: {
        formModel: {
          format_vector: DEFAULT_VECTOR_FORMAT,
          format_raster: 'geotiff',
          contourlines_step: '', // get by API for certain region
        },
      },
      images: [{ name: 'dem', format: 'png' }],
    },
    msbld: {
      id: 'msbld',
      serverId: 'msbld',
      to: { name: 'msbld', params: { productId: 'msbld' } },
      name: i18n.t('product.msbld.title'),
      icon: 'mdi-land-plots-circle',
      isWorldwide: true,
      description: i18n.t('product.msbld.description'),
      lastUpdated: '18.05.2023',
      productionTime: (regionCode) => getProductionTimeForCachedProducts({ lang: i18n.locale, regionCode }), // in minutes
      sources: [
        {
          text: 'Microsoft',
          link: 'https://planetarycomputer.microsoft.com/dataset/ms-buildings',
        },
        {
          text: 'Google',
          link: 'https://sites.research.google/open-buildings/',
        },
      ],
      tags: i18n.t('product.msbld.tags'),
      samplesUrl: `/${i18n.locale}/about/#formats-msbld`,
      dataStructureUrl: {
        ru: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vS9EG8OMqxvcPemo-IMWQi_enrZij710YvKPmk3si5lQS_-sA5wCNj4waXefq0zvoouerktx0wrAlNW/pubhtml',
        en: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRgaXygAc2KXQp-nGK2t8KcBYqCb6cttxCO6UzUkIdSafVxQmxhUBrGIV3pLjCMi64Ys0_tAwa61ZAy/pubhtml',
        es: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTmmnjYBzi-G2Up5_iK690ZcF0DVCOTM79Z0vtzKSrYL3ykMQ4MPBx1XDxtVymCw_IzmrWgidx67CnW/pubhtml',
        fr: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRViNGrElQJ0zL-a8uzyixLd-N-oBYymSI4x9nMGLyUI9hrJKXjVR2703qGRF5ePucXcDTTOha3zxut/pubhtml',
      },
      demoMapUrl: {
        en: 'https://demo.nextgis.com/resource/7317/display',
        ru: 'https://demo.nextgis.ru/resource/6152/display',
      },
      demoMapImage: `${process.env.VUE_APP_PUBLIC_PATH}img/product/msbld_demo_map_bg.png`,
      productOptions: {
        formFields: {
          format: {
            name: 'format',
            label: `${i18n.t('product.format')}:`,
            widget: 'select',
            options: getFullFormatList(COMMON_VECTOR_FORMATS),
            serverErrors: '',
            errors: [],
          },
        },
      },
      productModel: {
        formModel: {
          format: DEFAULT_VECTOR_FORMAT,
        },
      },
      layerList: {
        attrs: {
          disabled: true,
        },
      },
      images: [
        { name: 'msbldSatellite', format: 'png' },
        { name: 'msbld', format: 'png' },
      ],
    },
    msrd: {
      id: 'msrd',
      serverId: 'msrd',
      to: { name: 'msrd', params: { productId: 'msrd' } },
      name: i18n.t('product.msrd.title'),
      icon: 'mdi-road-variant',
      isWorldwide: true,
      description: i18n.t('product.msrd.description'),
      lastUpdated: '27.03.2023',
      productionTime: (regionCode) => getProductionTimeForCachedProducts({ lang: i18n.locale, regionCode }), // in minutes
      sources: [
        {
          text: 'Microsoft',
          link: 'https://github.com/microsoft/RoadDetections',
        },
      ],
      tags: i18n.t('product.msrd.tags'),
      samplesUrl: `/${i18n.locale}/about/#formats-msrd`,
      dataStructureUrl: {
        ru: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vSx74nB_TPHRYNZTBFSRK5eEi_oxEBFCShT30VRYmk_sisD4qVcwRb-5F_IWiP2x-0pRGFWH8piHnWC/pubhtml',
        en: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT6LtVjaJy8GAOwBGFGqjL5NX0meddQbwG0qtiZuqBPMmDsjLAxYWM4ARzFDegb2UG2vhlHaLefOP2s/pubhtml',
      },
      demoMapUrl: {
        en: 'https://demo.nextgis.com/resource/7251/display',
        ru: 'https://demo.nextgis.ru/resource/7076/display',
      },
      demoMapImage: `${process.env.VUE_APP_PUBLIC_PATH}img/product/msrd_demo_map_bg.png`,
      productOptions: {
        formFields: {
          format: {
            name: 'format',
            label: `${i18n.t('product.format')}:`,
            widget: 'select',
            options: getFullFormatList(COMMON_VECTOR_FORMATS),
            serverErrors: '',
            errors: [],
          },
        },
      },
      productModel: {
        formModel: {
          format: DEFAULT_VECTOR_FORMAT,
        },
      },
      layerList: {
        attrs: {
          disabled: true,
        },
      },
      images: [
        { name: 'msrdSat', format: 'png' },
        { name: 'msrd', format: 'png' },
      ],
    },
    overture: {
      id: 'overture',
      serverId: 'overture',
      to: { name: 'simpleDataProduct', params: { productId: 'overture' } },
      name: i18n.t('product.overture.title'),
      icon: 'mdi-earth-box',
      isWorldwide: true,
      description: i18n.t('product.overture.description'),
      lastUpdated: '12.03.2024',
      productionTime: 5, // in minutes
      sources: [
        {
          text: 'Overture Maps Foundation',
          link: 'https://overturemaps.org/',
        },
      ],
      tags: i18n.t('product.overture.tags'),
      samplesUrl: `/${i18n.locale}/about/#formats-overture`,
      dataStructureUrl: {
        ru: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRJd2RsUimlo1GegSpnJScUdi0kImAJYumnWwbqyb6B2JWMwT9BAyIcIybsqQEBqSllCSNWviGJI9fC/pubhtml?gid=0&single=true',
        en: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vS2K6m2aTT-CRLZJROrAcsnBAcDPEcWJkxdh9l1dbSxpkSQ3UzusYRixNfCL8v74tXB7DFYjxltRInP/pubhtml?gid=0&single=true',
      },
      demoMapUrl: {
        ru: 'https://demo.nextgis.ru/resource/7150/display',
        en: 'https://demo.nextgis.com/resource/7482/display',
      },
      demoMapImage: `${process.env.VUE_APP_PUBLIC_PATH}img/product/overture_demo_map_bg.png`,
      productOptions: {
        formFields: {
          format: {
            name: 'format',
            label: `${i18n.t('product.format')}:`,
            widget: 'select',
            options: getFullFormatList(COMMON_VECTOR_FORMATS),
            serverErrors: '',
            errors: [],
          },
        },
      },
      productModel: {
        formModel: {
          format: DEFAULT_VECTOR_FORMAT,
        },
      },
      layerList: {
        attrs: {
          disabled: true,
        },
      },
      images: [{ name: 'overture', format: 'png' }],
    },
    sat: {
      id: 'sat',
      serverId: 'sat',
      to: { name: 'simpleDataProduct', params: { productId: 'sat' } },
      name: i18n.t('product.sat.title'),
      icon: 'mdi-space-station',
      isWorldwide: true,
      description: i18n.t('product.sat.description'),
      productionTime: 120, // in minutes
      sources: [
        {
          text: 'Sentinel',
          link: 'https://dataspace.copernicus.eu/explore-data/data-collections/sentinel-data/sentinel-2',
        },
      ],
      tags: i18n.t('product.sat.tags'),
      samplesUrl: `/${i18n.locale}/about/#formats-sat`,
      demoMapUrl: {
        en: 'https://demo.nextgis.com/resource/5852/display?panel=info',
        ru: 'https://demo.nextgis.ru/resource/5848/display?panel=info',
      },
      demoMapImage: `${process.env.VUE_APP_PUBLIC_PATH}img/product/sat_demo_map_bg.jpg`,
      productOptions: {
        formFields: {
          format: {
            name: 'format',
            label: `${i18n.t('product.format')}:`,
            widget: 'select',
            options: getFullFormatList(['geotiff']),
            serverErrors: '',
            errors: [],
          },
          zoom_level: {
            name: 'zoom_level',
            label: `${i18n.t('product.zoom_level.label')}:`,
            widget: 'select',
            options: [
              // { value: 13, text: i18n.t('product.zoom_level.value_z13') },
              { value: 14, text: i18n.t('product.zoom_level.value_z14') },
            ],
            serverErrors: '',
            errors: [],
          },
        },
      },
      productModel: {
        formModel: {
          format: 'geotiff',
          zoom_level: 14,
        },
      },
      layerList: {
        attrs: {
          disabled: true,
        },
      },
      images: [{ name: 'satZ13', format: 'png' }],
    },
    gkh: {
      id: 'gkh',
      serverId: 'reforma',
      to: { name: 'simpleDataProduct', params: { productId: 'gkh' } },
      name: i18n.t('product.gkh.title'),
      icon: 'mdi-home-city',
      isWorldwide: false,
      description: i18n.t('product.gkh.description'),
      lastUpdated: '28.05.2024',
      productionTime: (regionCode) => getProductionTimeForCachedProducts({ lang: i18n.locale, regionCode }), // in minutes
      sources: [
        {
          text: i18n.t('product.gkh.reforma'),
          link: 'https://xn--p1aee.xn--p1ai/',
        },
      ],
      tags: i18n.t('product.gkh.tags'),
      samplesUrl: '/ru/about/#formats-gkh',
      dataStructureUrl: {
        ru: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vS32AGvxMh3_Yxuq_duKTIzQFfrJ88iEBciyn8ruhgGxAmyj2bcksH6JfoOs5p13YfZhPXAEqzRa783/pubhtml',
        en: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vS32AGvxMh3_Yxuq_duKTIzQFfrJ88iEBciyn8ruhgGxAmyj2bcksH6JfoOs5p13YfZhPXAEqzRa783/pubhtml',
      },
      demoMapUrl: {
        en: 'https://demo.nextgis.ru/resource/5467/display?panel=info',
        ru: 'https://demo.nextgis.ru/resource/5467/display?panel=info',
      },
      demoMapImage: `${process.env.VUE_APP_PUBLIC_PATH}img/product/gkh_demo_map_bg.png`,
      productOptions: {
        formFields: {
          format: {
            name: 'format',
            label: `${i18n.t('product.format')}:`,
            widget: 'select',
            options: getFullFormatList(COMMON_VECTOR_FORMATS),
            serverErrors: '',
            errors: [],
          },
        },
      },
      productModel: {
        formModel: {
          format: DEFAULT_VECTOR_FORMAT,
        },
      },
      layerList: {
        attrs: {
          disabled: true,
        },
      },
      images: [{ name: 'gkh', format: 'png' }],
    },
    oopt: {
      id: 'oopt',
      serverId: 'oopt',
      to: { name: 'simpleDataProduct', params: { productId: 'oopt' } },
      name: i18n.t('product.oopt.title'),
      icon: 'mdi-paw',
      isWorldwide: false,
      description: i18n.t('product.oopt.description'),
      lastUpdated: '14.12.2023',
      productionTime: (regionCode) => getProductionTimeForCachedProducts({ lang: i18n.locale, regionCode }), // in minutes
      sources: [
        {
          text: i18n.t('product.oopt.ooptRossii'),
        },
      ],
      tags: i18n.t('product.oopt.tags'),
      samplesUrl: `/ru/about/#formats-oopt`,
      dataStructureUrl: {
        ru: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQ11744S-kcUBR9gTOGv8ylhTVn2iEQzGA6m9dM0cEaKNEc0-CCm7fZTUPeftUeoms1cCf4uc7e3pjF/pubhtml',
        en: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQ11744S-kcUBR9gTOGv8ylhTVn2iEQzGA6m9dM0cEaKNEc0-CCm7fZTUPeftUeoms1cCf4uc7e3pjF/pubhtml',
      },
      demoMapUrl: {
        ru: 'https://demo.nextgis.ru/resource/6144/display?panel=info',
        en: 'https://demo.nextgis.com/resource/6744/display?panel=info',
      },
      demoMapImage: `${process.env.VUE_APP_PUBLIC_PATH}img/product/oopt_demo_map_bg.png`,
      productOptions: {
        formFields: {
          format: {
            name: 'format',
            label: `${i18n.t('product.format')}:`,
            widget: 'select',
            options: getFullFormatList(COMMON_VECTOR_FORMATS),
            serverErrors: '',
            errors: [],
          },
        },
      },
      productModel: {
        formModel: {
          format: DEFAULT_VECTOR_FORMAT,
        },
      },
      layerList: {
        attrs: {
          disabled: true,
        },
      },
      images: [
        { name: 'oopt', format: 'png' },
        { name: 'ooptAttrs', format: 'png' },
      ],
    },
    rnlic: {
      id: 'rnlic',
      serverId: 'rnlic',
      to: { name: 'simpleDataProduct', params: { productId: 'rnlic' } },
      name: i18n.t('product.rnlic.title'),
      icon: 'mdi-water',
      isWorldwide: false,
      description: i18n.t('product.rnlic.description'),
      lastUpdated: LAST_UPDATED_TODAY,
      productionTime: 5, // in minutes
      sources: [
        {
          text: i18n.t('product.rnlic.rosgeolfond'),
          link: 'https://rfgf.ru',
        },
      ],
      tags: i18n.t('product.rnlic.tags'),
      samplesUrl: `/ru/about/#formats-rnlic`,
      dataStructureUrl: {
        ru: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTDwgzllu93JNaL60BYHyTrirCqUigFwKYatMosvABIr8rVVHfmvHAaAy0D2gXFOw1Qcl2A2RN4O374/pubhtml',
        en: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTDwgzllu93JNaL60BYHyTrirCqUigFwKYatMosvABIr8rVVHfmvHAaAy0D2gXFOw1Qcl2A2RN4O374/pubhtml',
      },
      demoMapUrl: {
        ru: 'https://demo.nextgis.ru/resource/6246/display',
        en: 'https://demo.nextgis.ru/resource/6246/display',
      },
      demoMapImage: `${process.env.VUE_APP_PUBLIC_PATH}img/product/rnlic_demo_map_bg.png`,
      productOptions: {
        formFields: {
          format: {
            name: 'format',
            label: `${i18n.t('product.format')}:`,
            widget: 'select',
            options: getFullFormatList(COMMON_VECTOR_FORMATS),
            serverErrors: '',
            errors: [],
          },
        },
      },
      productModel: {
        formModel: {
          format: DEFAULT_VECTOR_FORMAT,
        },
      },
      layerList: {
        attrs: {
          disabled: true,
        },
      },
      images: [
        { name: 'rnlic', format: 'png' },
        { name: 'rnlicAttrs', format: 'png' },
      ],
    },
    heritage: {
      id: 'heritage',
      serverId: 'heritage',
      to: { name: 'simpleDataProduct', params: { productId: 'heritage' } },
      name: i18n.t('product.heritage.title'),
      icon: 'mdi-castle',
      isWorldwide: false,
      description: i18n.t('product.heritage.description'),
      lastUpdated: '20.10.2024',
      productionTime: 5,
      sources: [
        {
          text: 'Wikivoyage',
          link: 'https://ru.wikivoyage.org/wiki/Культурное_наследие_России',
        },
      ],
      tags: i18n.t('product.heritage.tags'),
      samplesUrl: `/ru/about/#formats-heritage`,
      dataStructureUrl: {
        ru: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRugZizn-rKnihRS6UYLulQUQbOBrUnkJQtiuGTWwwD39SmY6M5oLa94GD1Oww3eRf50_XM6atyP1Xw/pubhtml',
        en: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTC_wgWY28Y3QjgvJ-Q8Jmu6Doxxhpz-Hm_b3JwEjqcpk8bpQmRJq6ldwgZoHWbWxk-FC2Wi0nyjXnY/pubhtml',
      },
      demoMapUrl: {
        en: 'https://demo.nextgis.com/resource/7691/display?panel=layers',
        ru: 'https://demo.nextgis.ru/resource/8861/display?panel=layers',
      },
      demoMapImage: `${process.env.VUE_APP_PUBLIC_PATH}img/product/heritage_demo_map_bg.png`,
      productOptions: {
        formFields: {
          format: {
            name: 'format',
            label: `${i18n.t('product.format')}:`,
            widget: 'select',
            options: getFullFormatList(COMMON_VECTOR_FORMATS),
            serverErrors: '',
            errors: [],
          },
        },
      },
      productModel: {
        formModel: {
          format: DEFAULT_VECTOR_FORMAT,
        },
      },
      layerList: {
        attrs: {
          disabled: true,
        },
      },
      images: [{ name: 'heritage', format: 'png' }],
    },
  });
};

const getProductSettingsByKeys = ({ keys, i18n }) => {
  const translatedProductSettings = getProductSettings(i18n);
  return keys.map((key) => translatedProductSettings[key]);
};
const getProductSettingsByServerIds = ({ serverIds, i18n }) => {
  return Object.values(getProductSettings(i18n)).filter((product) => serverIds.includes(product.serverId));
};

const getRegionalProductSettings = (i18n) =>
  Object.values(getProductSettings(i18n)).filter((product) => (i18n.locale !== 'ru' ? product.isWorldwide : true));

export {
  getProductSettings,
  getProductSettingsByKeys,
  getProductionTimeForCachedProducts,
  getProductSettingsByServerIds,
  getRegionalProductSettings,
};
